<template>
  <div class='w104  h104' ref="gaugeDom"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    data: {},
    min: {},
    max: {},
    colorArr: {},
    showper: {}
  },
  name: 'gaugeCharts',
  data () {
    return {
      myChart: null
    };
  },
  watch: {
    data () {
      this.updateChart()
    }
  },
  created () {
  },
  mounted () {
    this.initChart()
  },
  methods: {
    initChart () {
      this.myChart = echarts.init(this.$refs.gaugeDom)
      const initOption = {
        series: [
          {
            type: 'gauge',
            name: '温度',
            center: ['50%', '50%'],
            radius: '90%',
            startAngle: 180,//起始角度
            endAngle: 0,//终止角度
            min: this.min,//最小值
            max: this.max,//最大值
            splitNumber: 3,  //进度条分段
            //进度条配置
            progress: {
              show: false,
              width: 10
            },
            //是否显示指针
            pointer: {
              icon: 'circle',
              width: 10,
              offsetCenter: [0, '-62%'],
              itemStyle: {
                color: 'rgba(255, 255, 255, 1)',
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,.5)'
              }
            },
            //内侧刻度线取消
            axisTick: {
              show: false
            },
            //是否显示仪表盘轴线。
            axisLine: {
              show: true,
              roundCap: true,
              lineStyle: {
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, this.colorArr)
                  ]
                ],
                width: 8,
              }
            },

            //是否显示分割线
            splitLine: {
              show: false,
            },
            //是否显示刻度标签。
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false
            },
            title: {
              show: false
            },
            //中间文字
            detail: {
              valueAnimation: true,
              width: '60%',
              lineHeight: 40,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 24,  //文字大小
              fontWeight: '500',
              color: '#fff'
            }
          }
        ]
      }
      this.myChart.setOption(initOption)
      this.updateChart()
    },
    updateChart () {
      const updateOption = {
        series: [{
          data: [
            {
              value: this.data,
              itemStyle: {
                color: 'rgba(255,255,255,0)'
              },
              detail: {
                formatter: (value) => {
                  if (this.showper) {
                    return value + '%'
                  } else {
                    return value
                  }
                }
              }

            }
          ]
        }]
      }
      this.myChart.setOption(updateOption)
    }
  },
}
</script>

<style lang='scss' scoped>
</style>