<template>
  <div class='content color-f'>
    <header class="header_box">
      <div class="time_box">{{nowDate}} {{week}}</div>
      <div class="name_box">{{title}}</div>
      <img src="@/assets/img/5.5/back.png" class="w122 h56 back_img" @click="back" />
    </header>

    <!-- 内容 -->
    <div class="mt30 pl60 pr60" style="overflow: hidden;">
      <div class="move_box" :style="{'--updateStyle':speend+'s'}">
        <div v-for="(item2,i2) in 2" :key="i2">
          <div class="flex flex-wrap row-between">
            <div
              :class="['table_item  w870 h367 pb30 radius20 mb30 pl90 pt6 flex row-column row-between',item.status==1?'table_item_outline':'table_item_online']"
              v-for="(item,i) in list" :key="i">
              <!-- 上 -->
              <div class="flex row-column col-center pt6">
                <div class="flex col-center">
                  <img src="@/assets/img/5.5/location.png" class="w21 h28 mr13" style="dispaly:block">
                  <div class="fs20 fw-500 fm-f">{{item.name}}</div>
                </div>
                <div class="fs20 fw-500 fm-p mt6">{{item.status=='1'?'未运行':'运行中'}}</div>
              </div>
              <!-- 中 -->
              <div class="flex row-between col-center mr87">
                <div>
                  <div class="relative">
                    <img src="@/assets/img/5.5/xdj_slz.png" class="w104 h55">
                    <div class="bubble" v-for="(c1,i1) in 4" :key="i1+'q'" v-if="item.status!=1"></div>
                    <div class="bubble1" v-for="(c2,i2) in 4" :key="i2+'w'" v-if="item.status!=1"></div>
                    <div class="bubble2" v-for="(c3,i3) in 4" :key="i3+'e'" v-if="item.status!=1"></div>
                    <div class="bubble3" v-for="(c4,i4) in 4" :key="i4+'r'" v-if="item.status!=1"></div>
                    <div class="bubble4" v-for="(c5,i5) in 4" :key="i5+'t'" v-if="item.status!=1"></div>
                    <div class="bubble5" v-for="(c6,i6) in 4" :key="i6+'y'" v-if="item.status!=1"></div>
                    <div class="bubble6" v-for="(c7,i7) in 4" :key="i7+'u'" v-if="item.status!=1"></div>
                    <div class="bubble7" v-for="(c8,i8) in 4" :key="i8+'i'" v-if="item.status!=1"></div>
                    <div class="bubble8" v-for="(c9,i9) in 4" :key="i9+'o'" v-if="item.status!=1"></div>
                    <div class="bubble9" v-for="(c10,i10) in 4" :key="i10+'p'" v-if="item.status!=1"></div>
                    <div class="bubble10" v-for="(c11,i11) in 4" :key="i11+'a'" v-if="item.status!=1"></div>
                  </div>
                </div>
                <div v-if="item.status!=1">
                  <div class="normal_box" v-if="item.tvoc<0.5">合格</div>
                  <div class="warning_box" v-if="item.tvoc>=0.5">轻微<br>超标</div>
                  <!-- <div class="error_box" v-if="item.tvoc>=0.5">严重<br>超标</div> -->
                </div>
              </div>
              <div class="flex row-between">
                <div class="flex">
                  <div class="gauge_item mr67">
                    <GaugeCharts :data='item.temperature' min="-10" max='50' :colorArr='colorArr1' />
                    <div class="gauge_item_name">温度℃</div>
                  </div>
                  <div class="gauge_item mr67">
                    <GaugeCharts :data='item.humidity' min="0" max='100' :colorArr='colorArr2' />
                    <div class="gauge_item_name">湿度%</div>
                  </div>
                  <!-- <div class="gauge_item mr50">
                    <GaugeCharts :data='item.tvoc' min="0.3" max='0.5' :colorArr='colorArr3' />
                    <div class="gauge_item_name">TVOCmg/m³</div>
                  </div> -->
                  <div class="gauge_item">
                    <GaugeCharts :data='item.xiaoshalv' min="0" max='100' :colorArr='colorArr3' :showper='true' />
                    <div class="gauge_item_name">当前消杀率</div>
                  </div>
                </div>
                <div class="mr30">
                  <div class="xiaodu_time_bg flex col-center row-center">
                    <div class="text-center">
                      <span class="fs16 fw-600">{{(item.workingTime/3600).toFixed(1)}}<br></span>
                      <span class="fs11">小时</span>
                    </div>
                  </div>
                  <div class="mt12 text-center">该设备当前消毒时长</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GaugeCharts from './components/gaugeCharts.vue'
import { airUse } from '@/api/devicesNowTime'
import clearTime from '@/mixin/clearTime'
import { formatDate } from '@/filters'
export default {
  components: { GaugeCharts },
  mixins: [clearTime],
  name: 'DevicesNowTime',
  data () {
    return {
      nowDate: '',
      week: '',
      list: [],
      title: '',
      speend: null,
      colorArr1: [
        {
          offset: 0,
          color: 'rgba(66, 117, 170, 1)'
        },
        {
          offset: 0.25,
          color: 'rgba(68, 170, 170, 1)'
        },
        {
          offset: 0.50,
          color: 'rgba(72, 171, 137, 1)'
        },
        {
          offset: 0.75,
          color: 'rgba(145, 190, 109, 1)'
        },
        {
          offset: 1,
          color: 'rgba(249, 65, 68, 1)'
        }
      ],
      colorArr2: [
        {
          offset: 0,
          color: '#FCD2A4'
        },
        {
          offset: 0.25,
          color: '#91BE6D'
        },
        {
          offset: 0.50,
          color: '#48AB89'
        },
        {
          offset: 0.75,
          color: '#44AAAA'
        },
        {
          offset: 1,
          color: '#4275AA'
        }
      ],
      colorArr3: [
        {
          offset: 0,
          color: '#91BE6D'
        },
        {
          offset: 0.25,
          color: '#91BE6D'
        },
        {
          offset: 0.50,
          color: '#F9C750'
        },
        {
          offset: 0.75,
          color: '#F9844A'
        },
        {
          offset: 1,
          color: '#F94144'
        }
      ],
      timer2: null
    };
  },
  created () {
    this.title = sessionStorage.getItem('a_title') || '铭智生科可视化界面'
    this.getDate()
    this.timer2 = null
    this.timer2 = setInterval(() => {
      this.getDate()
    }, 500)
  },
  mounted () {
    this.getList()
    this.timer = setInterval(() => {
      this.getList()
    }, 1000)
  },
  beforeUnmount () {
    //清除定时器
    clearInterval(this.timer2)
    this.timer2 = null
  },
  methods: {
    async getList () {
      const res = await airUse()
      this.list = res.data
      this.speend = this.list.length * 6
      this.list.forEach(item => {
        if (item.useTime / 3600 > 2) {
          item.xiaoshalv = 99
        } else {
          item.xiaoshalv = ((item.useTime / 3600) / 2 * 100).toFixed(0)
        }
      })
    },
    getDate () {
      const timestamp = new Date().getTime();
      this.nowDate = formatDate(timestamp, 'yyyy年MM月dd日 hh:mm')
      const dayOfWeek = new Date().getDay();
      const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      this.week = days[dayOfWeek];
    },
    back () {
      this.$router.back()
    }
  },
}
</script>

<style lang='scss' scoped>
@import "./index";
.header_box {
  position: relative;
  width: 100%;
  height: 90px;
  background: url("~@/assets/img/5.5/top_bg.png") no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  .time_box {
    position: absolute;
    left: 20px;
    top: 41px;
    font-size: 18px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
  }
  .name_box {
    position: absolute;
    left: 50%;
    top: 11px;
    height: 50%;
    transform: translateX(-50%);
    font-size: 37px;
  }
  .back_img {
    position: absolute;
    right: 20px;
    top: 25px;
    cursor: pointer;
  }
}

.table_item {
  .status_box,
  .out_status {
    width: 110px;
    height: 50px;
    border-radius: 12px 0px 0px 12px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    text-align: center;
    line-height: 50px;
  }
  .status_box {
    background: #07e9d5;
    color: #05444a;
  }
  .out_status {
    background: #ededed;
    color: #000000;
  }
  .normal_box,
  .warning_box,
  .error_box {
    font-size: 16px;
    font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
    text-align: center;
    line-height: 92px;
    width: 92px;
    height: 92px;
    font-size: 16px;
    font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  }
  .normal_box {
    background: url("~@/assets/img/5.5/success.png") no-repeat;
    background-size: 100% 100%;
  }
  .warning_box {
    background: url("~@/assets/img/5.5/warning.png") no-repeat;
    background-size: 100% 100%;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error_box {
    background: url("~@/assets/img/5.5/error.png") no-repeat;
    background-size: 100% 100%;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gauge_item {
    position: relative;
    padding-top: 15px;
    width: w104;
    height: 125px;
    .gauge_item_name {
      position: absolute;
      text-align: center;
      left: 50%;
      bottom: 18px;
      transform: translateX(-50%);
      font-size: 16px;
      width: 100%;
    }
  }
  .xiaodu_time_bg {
    width: 204px;
    height: 76px;
    background: url("~@/assets/img/5.5/xiaodutime_bg.png") no-repeat;
    background-size: 100% 100%;
  }
}
.table_item_online {
  background: url("~@/assets/img/5.5/border_img.png") no-repeat;
  background-size: 100% 100%;
}
.table_item_outline {
  background: url("~@/assets/img/5.5/norun_border_img.png") no-repeat;
  background-size: 100% 100%;
}
.move_box {
  animation: move var(--updateStyle) linear infinite;
}
@keyframes move {
  0% {
  }
  100% {
    transform: translateY(-50%);
  }
}
</style>